











































































































































import {
  Component, Mixins, Model, Watch,
} from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import ValidatorMixin from '@/modules/shared/mixins/ValidatorMixin'

import UserModel from '@/modules/shared/entities/form-models/User'

import DeleteIcon from '@/modules/shared/components/icons/DeleteIcon.vue'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'

const AuthModule = namespace('auth')
const SharedModule = namespace('shared')

@Component({
  components: {
    DeleteIcon,
    PrimaryButton,
  },
})
export default class ProfileForm extends Mixins(ValidatorMixin) {
  @Model('input', { default: null, required: true })
  private model!: UserModel|null

  @AuthModule.Getter
  private readonly isAdmin!: boolean

  @SharedModule.Getter
  private readonly species!: unknown[]

  @SharedModule.Action
  private readonly updateUser!: (user: UserModel) => Promise<void>

  @Watch('model.password')
  changePassword(val: string) {
    if (val === '') this.resetValidation()
  }

  private rePassword = ''

  private get isOpen(): boolean {
    return this.model !== null
  }

  private set isOpen(open: boolean) {
    if (open === false) {
      this.reset()
      this.$emit('input', null)
    }
  }

  private confirmed(val: string) {
    return (this.model?.password === val) || this.$i18n.t('admin.user.form.fields.re_password.error.not_match')
  }

  private async submit() {
    if (!this.validate()) return

    try {
      await this.updateUser(this.model!)

      this.isOpen = false
    } catch (err) {
      console.error(err)
    }
  }
}
