





















































import { Component, Vue } from 'vue-property-decorator'

import HomeIcon from '@/modules/shared/components/icons/HomeIcon.vue'
import Icon from '@/modules/shared/components/Icon.vue'
import LocaleChanger from '@/modules/shared/components/LocaleChanger.vue'
import MenuDialog from '@/modules/shared/components/menu/MenuDialog.vue'
import ProfileButton from '@/modules/shared/components/ProfileButton.vue'

@Component({
  components: {
    HomeIcon,
    Icon,
    LocaleChanger,
    MenuDialog,
    ProfileButton,
  },
})
export default class AppBar extends Vue {
  private menu = false;

  private get restrictedArea() {
    return this.$route.matched.some((record) => record.meta.auth)
  }

  private get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  private get isDesktop(): boolean {
    return !this.isMobile
  }
}
