










import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Icon extends Vue {
  @Prop({ required: true })
  private readonly name!: string;

  @Prop({ default: false, type: Boolean })
  private readonly outlined!: boolean;
}
