<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1" fill="white">
      <rect x="4" y="4" width="22" height="6" rx="1"/>
    </mask>
    <rect x="4" y="4" width="22" height="6" rx="1" stroke="#333333" stroke-width="4" mask="url(#path-1-inside-1)"/>
    <mask id="path-2-inside-2" fill="white">
      <rect x="4" y="12" width="7" height="14" rx="1"/>
    </mask>
    <rect x="4" y="12" width="7" height="14" rx="1" stroke="#333333" stroke-width="4" mask="url(#path-2-inside-2)"/>
    <mask id="path-3-inside-3" fill="white">
      <rect x="13" y="12" width="13" height="14" rx="1"/>
    </mask>
    <rect x="13" y="12" width="13" height="14" rx="1" stroke="#333333" stroke-width="4" mask="url(#path-3-inside-3)"/>
  </svg>
</template>

<script>
export default {
  name: 'HomeIcon',
}
</script>
