





























import { Component, Vue } from 'vue-property-decorator'

import Icon from '@/modules/shared/components/Icon.vue'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'

@Component({
  components: {
    Icon,
    PrimaryButton,
  },
})
export default class LocaleChanger extends Vue {
  langs = ['pt-BR', 'en']
}
