



















































import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import UserModel from '@/modules/shared/entities/form-models/User'

import Icon from '@/modules/shared/components/Icon.vue'
import PrimaryButton from '@/modules/shared/components/PrimaryButton.vue'
import ProfileForm from '@/modules/shared/components/ProfileForm.vue'

const authModule = namespace('auth')
const sharedModule = namespace('shared')

@Component({
  components: {
    Icon,
    PrimaryButton,
    ProfileForm,
  },
})
export default class ProfileButton extends Vue {
  @authModule.Getter
  private readonly firstName!: string

  @authModule.Getter
  private readonly userId!: number

  @authModule.Getter
  private readonly isAuthenticated!: boolean

  @authModule.Action
  private readonly logout!: () => Promise<void>

  @sharedModule.Action
  private readonly fetchUserById!: (id: number) => Promise<UserModel>

  @sharedModule.Action
  private readonly fetchSpecies!: () => Promise<void>

  private userProfile: UserModel|null = null

  private administrativeArea() {
    this.$router.push({ name: 'admin.dashboard' })
  }

  private async editProfile() {
    const [, user] = await Promise.all([
      this.fetchSpecies(),
      this.fetchUserById(this.userId),
    ])

    this.userProfile = user
  }

  private async getOut() {
    await this.logout()

    this.$router.replace({ name: 'home' })
  }
}
