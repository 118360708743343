














import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

import AppBar from '@/modules/shared/components/AppBar.vue'
import AppFooter from '@/modules/shared/components/AppFooter.vue'
import ActivityIndicator from '@/modules/behavior/components/ActivityIndicator.vue'

const behaviorModule = namespace('behavior')

@Component({
  components: {
    AppBar,
    AppFooter,
    ActivityIndicator,
  },
})
export default class App extends Vue {
  @behaviorModule.Getter
  private readonly loadingVisible!: boolean
}
