




















































import { Component, Mixins } from 'vue-property-decorator'

import { ViewDataScope } from '@/modules/shared/entities/ViewData'

import ViewDataMixin from '@/modules/shared/mixins/ViewDataMixin'

@Component
export default class AppFooter extends Mixins(ViewDataMixin) {
  viewDataScope = ViewDataScope.footer

  private get restrictedArea() {
    return this.$route.matched.some((record) => record.meta.auth)
  }

  private get menuItems() {
    return [
      {
        title: this.$t('shared.menu.1.title'),
        to: { name: 'team' },
      },
      {
        title: this.$t('shared.menu.2.title'),
        to: { name: 'contributors' },
      },
      {
        title: this.$t('shared.menu.3.title'),
        to: { name: 'publications' },
      },
      {
        title: this.$t('shared.menu.4.title'),
        to: { name: 'contact' },
      },
    ]
  }

  private get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }
}
