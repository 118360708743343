











import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ActivityIndicator extends Vue {
  @Prop({ default: false, type: Boolean })
  private readonly show!: boolean
}
