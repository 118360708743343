

















import { Component, Mixins, Prop } from 'vue-property-decorator'

import { ViewDataScope } from '@/modules/shared/entities/ViewData'

import ViewDataMixin from '@/modules/shared/mixins/ViewDataMixin'

import Menu from './Menu.vue'

@Component({
  components: {
    Menu,
  },
})
export default class MenuDialog extends Mixins(ViewDataMixin) {
  @Prop({ type: Boolean }) value!: boolean;

  viewDataScope = ViewDataScope.menu
}
