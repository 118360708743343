














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class PrimaryButton extends Vue {
  @Prop({ default: false, type: Boolean }) private invert!: boolean

  private get isDesktop(): boolean {
    return this.$vuetify.breakpoint.mdAndUp
  }
}
