






































import { Component, Vue } from 'vue-property-decorator'

import LocaleChanger from '@/modules/shared/components/LocaleChanger.vue'
import ProfileButton from '@/modules/shared/components/ProfileButton.vue'

import Icon from '../Icon.vue'
import MenuItem from './MenuItem.vue'

@Component({
  components: {
    Icon,
    LocaleChanger,
    MenuItem,
    ProfileButton,
  },
})
export default class Menu extends Vue {
  private get menuItems() {
    return [
      {
        title: this.$t('shared.menu.1.title'),
        subtitle: this.$t('shared.menu.1.text'),
        to: { name: 'team' },
      },
      {
        title: this.$t('shared.menu.2.title'),
        subtitle: this.$t('shared.menu.2.text'),
        to: { name: 'contributors' },
      },
      {
        title: this.$t('shared.menu.3.title'),
        subtitle: this.$t('shared.menu.3.text'),
        to: { name: 'publications' },
      },
      {
        title: this.$t('shared.menu.5.title'),
        subtitle: this.$t('shared.menu.5.text'),
        to: { name: 'perspective' },
      },
      {
        title: this.$t('shared.menu.4.title'),
        subtitle: this.$t('shared.menu.4.text'),
        to: { name: 'contact' },
      },
    ]
  }

  private get isMobile(): boolean {
    return this.$vuetify.breakpoint.smAndDown
  }

  private get isDesktop(): boolean {
    return !this.isMobile
  }

  private close() {
    this.$emit('close')
  }
}
