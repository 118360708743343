












import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MenuItem extends Vue {
  @Prop({ required: true }) readonly title!: string;

  @Prop({ default: '' }) readonly subtitle!: string;

  @Prop({ required: true }) readonly to!: string;
}
